@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Jost';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* MAP GL STYLE STARTS */
.mapboxgl-marker:hover {
  z-index: 2;
}
/* MAP GL STYLE ENDS */

/* LOCATION MARKER STYLE STARTS */
.swing {
  animation: swing ease-in-out 4s alternate;
  transform-origin: center -20px;
  float: left;
}

@keyframes swing {
  0% {
    transform: rotate(4deg);
  }
  20% {
    transform: rotate(-3deg);
  }
  40% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-1deg);
  }
  80% {
    transform: rotate(1deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* LOCATION MARKER STYLE ENDS */

/* MAPBOX STYLE STARTS */
.mapboxgl-ctrl-logo {
  display: none !important;
}
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}
/* MAPBOX STYLE ENDS */

/* SKELETON LOADING STARTS */
.skeleton-loading {
  background: linear-gradient(
    to right,
    transparent,
    rgba(83, 83, 83, 0.056),
    transparent
  );
  animation: shimmer 1.5s infinite ease-in-out;
  transform: translateX(-100%);
  overflow: hidden;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* SKELETON LOADING ENDS */

/* LOCATION LIST GRID STARTS */
.location-list-grid {
  padding: 8px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(
    auto-fill,
    minmax(280px, 1fr)
  ); /* Default for small screens */
  grid-auto-rows: minmax(264px, auto);
  grid-gap: 40px 12px;
}

/* Tablet (md: 640px and up) */
@media (min-width: 640px) {
  .location-list-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

/* Laptop (lg: 1280px and up) */
@media (min-width: 1280px) {
  .location-list-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

/* Desktop (xl: 1920px and up) */
@media (min-width: 1920px) {
  .location-list-grid {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

/* LOCATION LIST GRID ENDS */

/* DROPDOWN GRID STARTS */
.dropdown-grid {
  padding: 8px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-auto-rows: minmax(90px, auto);
  grid-gap: 16px;
}
/* DROPDOWN GRID ENDS */

/* EASING FUNCTIONS STARTS */
.ease-css {
  transition-timing-function: ease;
} /* The CSS default built-in `ease` */
.ease-css-in {
  transition-timing-function: ease-in;
} /* The CSS built-in `ease-in` */
.ease-css-out {
  transition-timing-function: ease-out;
} /* The CSS built-in `ease-out` */
.ease-css-in-out {
  transition-timing-function: ease-in-out;
} /* The CSS built-in `ease-in-out` */
.ease-in-sine {
  transition-timing-function: cubic-bezier(0.12, 0, 0.39, 0);
}
.ease-out-sine {
  transition-timing-function: cubic-bezier(0.61, 1, 0.88, 1);
}
.ease-in-out-sine {
  transition-timing-function: cubic-bezier(0.37, 0, 0.63, 1);
}
.ease-in-quad {
  transition-timing-function: cubic-bezier(0.11, 0, 0.5, 0);
}
.ease-out-quad {
  transition-timing-function: cubic-bezier(0.5, 1, 0.89, 1);
}
.ease-in-out-quad {
  transition-timing-function: cubic-bezier(0.45, 0, 0.55, 1);
}
.ease-in-cubic {
  transition-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
}
.ease-out-cubic {
  transition-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}
.ease-in-out-cubic {
  transition-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}
.ease-in-quart {
  transition-timing-function: cubic-bezier(0.5, 0, 0.75, 0);
}
.ease-out-quart {
  transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
}
.ease-in-out-quart {
  transition-timing-function: cubic-bezier(0.76, 0, 0.24, 1);
}
.ease-in-quint {
  transition-timing-function: cubic-bezier(0.64, 0, 0.78, 0);
}
.ease-out-quint {
  transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}
.ease-in-out-quint {
  transition-timing-function: cubic-bezier(0.83, 0, 0.17, 1);
}
.ease-in-expo {
  transition-timing-function: cubic-bezier(0.7, 0, 0.84, 0);
}
.ease-out-expo {
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.ease-in-out-expo {
  transition-timing-function: cubic-bezier(0.87, 0, 0.13, 1);
}
.ease-in-circ {
  transition-timing-function: cubic-bezier(0.55, 0, 1, 0.45);
}
.ease-out-circ {
  transition-timing-function: cubic-bezier(0, 0.55, 0.45, 1);
}
.ease-in-out-circ {
  transition-timing-function: cubic-bezier(0.85, 0, 0.15, 1);
}
.ease-in-back {
  transition-timing-function: cubic-bezier(0.36, 0, 0.66, -0.56);
}
.ease-out-back {
  transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
}
.ease-in-out-back {
  transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
}
/* EASING FUNCTIONS ENDS */

/* GENERIC PAGE STYLE STARTS  */
.genericContent {
  color: #333;
  padding: 1rem;
  line-height: 1.6;
  margin: 0 !important;
}
.genericContent h1,
.genericContent h2,
.genericContent h3,
.genericContent h4,
.genericContent h5 {
  margin-top: 0;
  margin-bottom: 10px;
}
.genericContent h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.genericContent h2 {
  font-size: 2rem;
}
.genericContent h3 {
  font-size: 1.75rem;
}
.genericContent h4 {
  font-size: 1.5rem;
}
.genericContent h5 {
  font-size: 1.25rem;
}
.genericContent p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.genericContent ul,
.genericContent ol {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 20px;
}
.genericContent ul {
  list-style-type: disc;
}
.genericContent ol {
  list-style-type: decimal;
}
.genericContent li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .genericContent h1 {
    font-size: 2rem;
  }

  .genericContent h2 {
    font-size: 1.75rem;
  }

  .genericContent h3 {
    font-size: 1.5rem;
  }

  .genericContent h4 {
    font-size: 1.25rem;
  }

  .genericContent h5 {
    font-size: 1rem;
  }

  .genericContent p {
    font-size: 0.875rem; /* 14px */
  }
}
.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  cursor: pointer;
  padding: 10px;
  text-align: left;
  outline: none;
  font-size: 1.5rem; /* Similar to h2 */
  border-bottom: 1px solid #000; /* Underline */
  width: 100%;
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 0px 10px 0px 10px;
}

.accordion-body.accordion-active {
  max-height: 500px;
  padding: 10px;
}

.accordion-header .button {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  border-width: 1px;
  font-weight: 500;
  border-color: #000000;
  background-color: #fff;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
}

/* GENERIC PAGE STYLE ENDS */

/* SWIPER STYLE STARTS */
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  right: 14px !important;
  bottom: 14px !important;
  left: unset !important;
  width: fit-content !important;
  color: #f1f1f1 !important;
  font-weight: 500;
  background-color: #1e1e1e99;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
}
/* SWIPER STYLE ENDS */

/* PRINT STYLE STARTS */
.print-overflow {
  overflow: hidden;
}

.print-scroll {
  overflow: auto;
}

@media print {
  .dont-print-break {
    page-break-inside: avoid;
  }

  .dont-print-this {
    display: none !important;
  }

  .dont-print-margin {
    margin: 0 !important;
  }

  .print-overflow {
    overflow: unset;
  }

  .print-bg-white {
    background-color: white !important;
  }

  .print-scroll {
    overflow: unset;
  }

  .dont-print-shadow {
    box-shadow: none !important;
  }

  .print-this {
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
  }
}
/* PRINT STYLE ENDS */

/* MOBILE SEARCH STARTS */
.mobile-search {
  border: 1px solid rgba(193, 193, 193, 0.173);
  border-bottom: 1px solid rgba(212, 212, 212, 0.071);
  border-right: 1px solid rgba(212, 212, 212, 0.107);
  box-shadow: 1px 1px 4px #d9d9d9;
}
/* MOBILE SEARCH ENDS */

/* MOBILE FILTER MODAL STARTS */
.mobile-filter-modal {
  border-top: 1px solid rgba(212, 212, 212, 0.512);
  border-right: 1px solid rgba(186, 186, 186, 0.26);
  border-left: 1px solid rgba(212, 212, 212, 0.453);
  box-shadow: 0px -2px 4px #9d9c9c29;
}
/* MOBILE FILTER MODAL ENDS */


/* SCREEN READER STYLE STARTS */ 
/* Remove the outline from non-interactive focusable elements */
[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0;
}

/* Accessibility utilities */
.screen-reader-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.screen-reader-only-focusable:active,
.screen-reader-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.skip-to-content:focus {
  background-color: white;
  font-size: 18px;
  padding: 0 24px;
  position: absolute;
  left: 3px;
  line-height: 64px;
  top: 3px;
  z-index: 10000;
}
/* SCREEN READER STYLE ENDS */ 
